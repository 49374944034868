<template>
  <div class="page template-1">
    <header class="page-header container">
      <h1>{{ mrpProgramInfo.name }}</h1>
      <BannerAid :bannerLink="`videos|${mrpSelectedProgramId}|${mrpSelectedCategory}|${mrpSelectedTopicId}`" />
    </header>
    <b-container class="page-body">
      <b-row>
        <b-col sm="6" offset="6" class="search-col">
          <search-form :i18n="translations.components" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="video-col">
          <h4 id="featuredVideo">{{ mrpProgramInfo.featuredVideoText }}</h4>
          <div class="embed-responsive embed-responsive-16by9" id="videoPlayerContainer">
            <vueVimeoPlayer
              ref="player"
              :video-id="videoID"
              :player-height="720"
              :autoplay="false"
              @ready="this.onReady"
            ></vueVimeoPlayer>
          </div>
        </b-col>
      </b-row>
      <b-row class="table-row">
        <b-col sm="12" class="table-col mt-3">
          <data-table
            :i18n="translations.components"
            :title="tableTitle"
            :tooltip="tooltip"
            :fields="fields"
            :items="videos"
            @video_click="videoClick($event)"
          ></data-table>
        </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import dataTable from '@/components/DataTableMRP.vue'
import searchForm from './SearchForm.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  name: 'mrp-videos',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      search: '',
      videoID: '288800270',
      playerReady: false,
      tooltip: 'Testing',
      fields: [
        {
          key: 'videoTitle',
          label: 'Name',
          sortable: true,
          tdClass: 'mrpVideoIconClass',
        },
        {
          key: 'updated',
          label: 'Updated',
          sortable: true,
        },
        {
          key: 'download_link',
          label: 'Download',
          sortable: false,
          tdClass: 'mrpDownloadClass',
        },
      ],
    }
  },
  components: {
    BannerAid: BannerAid,
    DataTable: dataTable,
    SearchForm: searchForm,
    vueVimeoPlayer: vueVimeoPlayer,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
      setMRPSelectedVideoId: 'mrp/setMRPSelectedVideoId',
      setMRPSearchVideoId: 'mrp/setMRPSearchVideoId',
    }),
    createTitle(code) {
      return {
        ax: 'Auxiliary',
        cm: 'Church Ministry',
        co: 'Officer',
        gc: 'GideonCard',
        ff: 'Faith Fund',
        mb: 'Membership',
        of: 'Officer',
        pw: 'Prayer & Personal Witnessing',
        sd: 'Scripture Distribution',
        st: 'Officer',
        xx: 'Program',
      }[code || 'xx']
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadMRPProgramInfo({ lang: this.prefCulture, restype: 'videos', programid: this.mrpSelectedProgramId }),
      ]).then(() => {
        if (this.mrpProgramInfo.videoListing.length > 0) {
          let watchVideoIndex = 0
          if (this.mrpSearchVideoId.length > 0) {
            const vidIndex = this.videos.map((e) => e.itemId).indexOf(this.mrpSearchVideoId)
            watchVideoIndex = vidIndex >= 0 ? vidIndex : 0
            this.setMRPSearchVideoId('')
          }
          this.videoClick(this.videos[watchVideoIndex])
        }
        this.setLoadingStatus(false)
      })
    },
    videoClick(data) {
      this.setMRPSelectedVideoId(data.itemId)
      this.showVideo(data.videoLink, data.download_link, data.videoTitle)
    },
    showVideo(fileId, fileDownloadLink, filename) {
      this.videoID = fileId.replace('https://player.vimeo.com/video/', '')
      this.videoFileDownloadLink = fileDownloadLink
      this.videoName = filename
      this.$refs.player.pause()
      this.displayVideo = 'block'
      var elmnt = document.getElementById('featuredVideo')
      elmnt.scrollIntoView()
    },
    onReady() {
      this.playerReady = true
    },
    async setDataTableFields() {
      const { tcDownload, tcName, tcUpdated } = this.translations.components['data-table-mrp']
      this.fields[0].label = tcName
      this.fields[1].label = tcUpdated
      this.fields[2].label = tcDownload
      return true
    },
  },
  computed: {
    ...mapGetters({
      mrpProgramInfo: 'mrp/mrpProgramInfo',
      mrpSearchVideoId: 'mrp/mrpSearchVideoId',
      mrpSelectedCategory: 'mrp/mrpSelectedCategory',
      mrpSelectedProgramId: 'mrp/mrpSelectedProgramId',
      mrpSelectedTopicId: 'mrp/mrpSelectedTopicId',
      prefCulture: 'user/userPreferredCulture',
    }),
    tableTitle() {
      return this.mrpProgramInfo.videoTitle
    },
    videos() {
      let newVideoList = this.mrpProgramInfo.videoListing.map((v) => {
        return {
          category: 'videos',
          itemId: v.VideoId,
          videoTitle: v.VideoName,
          videoLink: v.VideoLink,
          icon: v.VideoThumbnailId,
          type: 'MP4',
          downloadIcon: '479F189B-353E-48B3-817E-FC784AC59C1B', //GUID in sitecore for download icon
          download_link: v.VideoDownload,
          updated: v.VideoUpdated,
        }
      })

      return newVideoList
    },
  },
  async created() {
    //need a selected mrp program id
    if (!this.mrpSelectedProgramId) {
      this.$router.push('/resources/media-portal')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('data-table-mrp', 'mrp-search-form'),
      this.pageLoad(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.setDataTableFields()
    })
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
.page {
  .page-body {
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 10px;
    }
  }
}

.video-col {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  #videoPlayerContainer {
    max-height: 400px;
  }
}
.table-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  .table-col {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    background-color: #ffffff;
    padding: 15px 0;
    box-shadow: 0 9px 24px 6px rgba(157, 157, 157, 0.5);
  }
}
</style>
